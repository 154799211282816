export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "dark",
        themes: {
          dark: {
            dark: false,
            colors: {
              background: "#000",
              // surface: "#000",
              rating: "#f4c74d",
              cardTitle: "#6a040f", // "#ffeeba",
              linkColor: "#ecb050",
              jackpot: "#007bff",
              iconColor: "#b39566",
              toTop: "#f0f0f0",
              darkCard: "#ffeeba",
            },
          },
          light: {},
        },
      },
    });
  });
});
